import React from "react";
import "./paragraph.scss";

function Paragraph({ children, variant }) {
  let classes = [`paragraph`];
  if (variant) {
    classes.push(`paragraph--${variant}`);
  }

  return <p className={classes.join(" ")}>{children}</p>;
}

export default Paragraph;
