import React from "react";
import "./tier.scss";

function Tier({ children, id }) {
  const classes = [`tier`, `tier--${id}`];

  return (
    <div className={classes.join(" ")} id={id}>
      {children}
    </div>
  );
}

export default Tier;
