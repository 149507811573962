import React from "react";
import "./heading.scss";

function Heading({ children, element, type, variant }) {
  const Tag = `h${element}`;
  let classes = [`heading`];
  if (type) {
    classes.push(`heading--${type}`);
  }
  if (variant) {
    classes.push(`heading--${variant}`);
  }

  return <Tag className={classes.join(" ")}>{children}</Tag>;
}

export default Heading;
