import React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import Heading from "../components/heading";
import "./work-detail.scss";

import logo from "../images/logo-light.svg";

class WorkDetail extends React.Component {
  constructor(props) {
    super();
    this.children = props.children;
    this.title = props.title;
  }

  componentDidMount() {
    this.closeLink.focus();
  }

  closeOverlay(e, modal, closeTo) {
    if (!e.target.closest(".work-detail__inner")) {
      this.closeLink.click();
    }
  }

  render() {
    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <>
            <div className="work-detail__header">
              <img
                alt="Melanie Lepold's logo"
                className="work-detail__logo"
                src={logo}
              />
              <Heading element="1" type="3" variant="work-detail-title">
                {this.title}
              </Heading>
              {modal ? (
                <Link
                  to={closeTo}
                  className="button work-detail__close"
                  state={{ noScroll: true }}
                  ref={link => (this.closeLink = link)}
                >
                  Close
                </Link>
              ) : (
                <Link
                  to="/#work"
                  className="button work-detail__close"
                  ref={link => (this.closeLink = link)}
                >
                  Close
                </Link>
              )}
            </div>
            <div
              className="work-detail"
              onClick={e => this.closeOverlay(e, modal, closeTo)}
              tabIndex={0}
            >
              <div className="work-detail__inner">
                <div className="work-detail__content">{this.children}</div>
              </div>
            </div>
          </>
        )}
      </ModalRoutingContext.Consumer>
    );
  }
}

export default WorkDetail;
