import React from "react";
import Heading from "../components/heading";
import Paragraph from "../components/paragraph";
import "./work-detail-image.scss";

function WorkDetailImage({
  caption,
  fullWidth,
  images,
  imagesLeft,
  imagesRight,
  narrowLg,
  pairStackedSmall,
  variantSize,
}) {
  let classes = ["work-detail-image"];
  if (fullWidth) {
    classes.push("work-detail-image--full-width");
  }
  if (narrowLg) {
    classes.push("work-detail-image--narrow-lg");
  }
  if (variantSize) {
    classes.push(`work-detail-image--${variantSize}`);
  }

  let pairClasses = ["work-detail-image__image-pair"];
  if (pairStackedSmall) {
    pairClasses.push("work-detail-image__image-pair--stacked-small");
  }

  return (
    <div className={classes.join(" ")}>
      {images && (
        <div className="work-detail-image__image">
          {images.heading && (
            <Heading element="4" variant="work-detail-image">
              {images.heading}
            </Heading>
          )}
          <picture>
            <source media="(min-width: 1024px)" srcSet={images.lg} />
            <source media="(min-width: 768px)" srcSet={images.md} />
            <source media="(min-width: 0px)" srcSet={images.sm} />
            <img alt={images.alt} src={images.lg} />
          </picture>
        </div>
      )}
      {(imagesLeft || imagesRight) && (
        <div className={pairClasses.join(" ")}>
          {imagesLeft && (
            <div className="work-detail-image__image-pair-image work-detail-image__image-pair-image--left">
              {imagesLeft.heading && (
                <Heading element="4" variant="work-detail-image">
                  {imagesLeft.heading}
                </Heading>
              )}
              <picture>
                <source media="(min-width: 1024px)" srcSet={imagesLeft.lg} />
                <source media="(min-width: 768px)" srcSet={imagesLeft.md} />
                <source media="(min-width: 0px)" srcSet={imagesLeft.sm} />
                <img alt={imagesLeft.alt} src={imagesLeft.lg} />
              </picture>
            </div>
          )}
          {imagesRight && (
            <div className="work-detail-image__image-pair-image work-detail-image__image-pair-image--right">
              {imagesRight.heading && (
                <Heading element="4" variant="work-detail-image">
                  {imagesRight.heading}
                </Heading>
              )}
              <picture>
                <source media="(min-width: 1024px)" srcSet={imagesRight.lg} />
                <source media="(min-width: 768px)" srcSet={imagesRight.md} />
                <source media="(min-width: 0px)" srcSet={imagesRight.sm} />
                <img alt={imagesRight.alt} src={imagesRight.lg} />
              </picture>
            </div>
          )}
        </div>
      )}
      {caption && (
        <div className="work-detail-image__caption">
          <Paragraph variant="work-detail-caption">{caption}</Paragraph>
        </div>
      )}
    </div>
  );
}

export default WorkDetailImage;
