import React from "react";
import Heading from "../components/heading";
import "./work-detail-text.scss";

function WorkDetailText({ background, children, heading, tightBottom }) {
  const classes = ["work-detail-text"];
  if (tightBottom) {
    classes.push("work-detail-text--tight-bottom");
  }

  return (
    <div className={classes.join(" ")}>
      {heading && (
        <div className="work-detail-text__heading">
          <Heading element="3" type="2">
            {heading}
          </Heading>
        </div>
      )}
      <div className="work-detail-text__content">{children}</div>
    </div>
  );
}

export default WorkDetailText;
