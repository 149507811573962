import React from "react";
import Heading from "../components/heading";
import Paragraph from "../components/paragraph";
import "./work-detail-intro.scss";

function WorkDetailIntro({
  client,
  description,
  images,
  project,
  role,
  title,
}) {
  const classes = ["work-detail-intro", `work-detail-intro--${project}`];

  return (
    <div className={classes.join(" ")}>
      <div className="work-detail-intro__title">
        <Heading element="2" type="1">
          {title}
        </Heading>
      </div>
      <div className="work-detail-intro__image-wrapper">
        <div className="work-detail-intro__image">
          <picture>
            <source media="(min-width: 1024px)" srcSet={images.lg} />
            <source media="(min-width: 768px)" srcSet={images.md} />
            <source media="(min-width: 0px)" srcSet={images.sm} />
            <img alt={images.alt} src={images.lg} />
          </picture>
        </div>
      </div>
      <div className="work-detail-intro__description">{description}</div>
      <div className="work-detail-intro__meta">
        <div className="work-detail-intro__meta-item">
          <Paragraph variant="work-detail">
            <strong>My Role</strong>
          </Paragraph>
          <Paragraph variant="work-detail">{role}</Paragraph>
        </div>
        <div className="work-detail-intro__meta-item">
          <Paragraph variant="work-detail">
            <strong>Client</strong>
          </Paragraph>
          <Paragraph variant="work-detail">{client}</Paragraph>
        </div>
      </div>
    </div>
  );
}

export default WorkDetailIntro;
